<template>
    <v-card width="100%">
        <v-container>
            <p
                class="text-h6 mb-0"
                :style="{ color: parameters.secondaryColor }"
            >
                Entre em contato por e-mail
            </p>
            <p class="caption grey--text">
                Basta preencher o formulário abaixo. Retornaremos o mais breve
                possível.
            </p>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col class="py-0" :cols="$mq != 'lg' ? 12 : 6">
                        <v-text-field
                            v-model="contact.contactModel.name"
                            :rules="nameRules"
                            label="Nome"
                            placeholder="Ex.: João da Silva"
                            type="text"
                            required
                            dense
                            flat
                            outlined
                            :color="parameters.primaryColor"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col class="py-0" :cols="$mq != 'lg' ? 12 : 6">
                        <v-text-field
                            v-model="contact.contactModel.email"
                            :rules="emailRules"
                            label="E-mail"
                            placeholder="Ex.: joao@email.com"
                            type="text"
                            required
                            dense
                            flat
                            outlined
                            :color="parameters.primaryColor"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" :cols="$mq != 'lg' ? 12 : 6">
                        <v-text-field
                            v-model="contact.contactModel.cellphone"
                            :rules="cellPhoneRules"
                            label="Celular"
                            type="text"
                            placeholder="Ex.: (99) 99999-9999"
                            required
                            v-mask="'(##) #####-####'"
                            dense
                            flat
                            outlined
                            :color="parameters.primaryColor"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" :cols="$mq != 'lg' ? 12 : 6">
                        <v-text-field
                            v-model="contact.contactModel.phone"
                            :rules="phoneRules"
                            label="Telefone"
                            placeholder="Ex.: (99) 9999-9999"
                            type="text"
                            dense
                            flat
                            outlined
                            v-mask="'(##) #####-####'"
                            :color="parameters.primaryColor"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <v-textarea
                            v-model="contact.contactModel.message"
                            label="Digite aqui sua mensagem"
                            counter
                            flat
                            outlined
                            dense
                            placeholder="Deixe aqui sua sugestão, problema ou recado..."
                            :color="parameters.primaryColor"
                            :rules="notesRules"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            block
                            :color="parameters.secondaryColor"
                            dark
                            @click="sendMessage"
                        >
                            Enviar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
    data: () => ({
        valid: true,
        nameRules: [
            (v) => !!v || "Nome é obrigatória",
            (v) =>
                (v &&
                    /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{2,})+$/i.test(
                        v
                    )) ||
                "Nome inválido",
        ],
        cellPhoneRules: [
            (v) => !!v || "Celular é obrigatório",
           /*  (v) =>
                (v &&
                    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(
                        v.replace(/[^\d]/g, "")
                    )) ||
                "Celular inválido", */
        ],
        phoneRules: [
            /* (v) => (v == "" || /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v.replace(/[^\d]/g, ""))) || "Telefone inválido", */
        ],
        emailRules: [
            (v) => !!v || "E-mail é obrigatório",
            (v) =>
                (v && /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(v)) ||
                "E-mail inválido",
        ],
        notesRules: [(v) => v.length <= 250 || "Max 250 characters"],
    }),
    directives: { VueMaskDirective },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        contact: {
            get() {
                return this.$store.state.contact;
            },
        },
    },
    methods: {
        sendMessage() {
            if (this.$refs.form.validate()){
                this.$store.dispatch("contact/sendMessage");
            }
        },
    },
};
</script>

<style>
</style>