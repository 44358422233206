<template>
    <GmapMap
        :center="parameters.store.geoLocation"
        :zoom="16"
        map-type-id="terrain"
        class="gmap"
        :options="{streetViewControl: false}"
    >
        <GmapMarker
            v-for="(position, index) in markers"
            :key="index"
            :position="position"
            clickable
            :draggable="false"
            @click="center = position"
        />
    </GmapMap>
</template>

<script>
export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        markers(){
            return [
                this.parameters.store.geoLocation
            ]
        }
    },
};
</script>

<style scoped>
    .gmap{
        width: 100%;
        height: 300px;
    }
</style>