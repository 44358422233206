<template>
    <v-main style="min-height: 80% !important;" >
        <v-row no-gutters>
            <v-col cols="12">
                <v-img 
                    :src="logoContact"
                    :alt="parameters.store.cf1fant"
                    aspect-ratio=""
                    max-height="480px !important"/>
            </v-col>
        </v-row>
        <v-container class="pb-12">
            <v-row>
                <v-col cols="12" class="pt-6">
                    <p class="text-h6" :style="{color: parameters.primaryColor}">Entre em contato e siga-nos nas redes sociais</p>
                </v-col>
                <v-col cols="12" class="align-center">
                    <template v-for="socialMedia in parameters.store.socialMedias">
                        <v-btn
                            v-if="socialMedia.b2mlink"
                            :key="socialMedia.icon"
                            class="mx-2"
                            :color="parameters.primaryColor"
                            icon
                            @click="openSocialMedia(socialMedia.b2mlink)"
                        >
                            <v-icon size="24px">
                                {{ socialMedia.icon }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-btn
                        v-if="parameters.store.b2bverwhat"
                        class="mx-4"
                        :color="parameters.primaryColor"
                        icon
                        @click="openWhatsApp"
                    >
                        <v-icon size="24px">
                            mdi-whatsapp
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="pt-12">
                    <v-list-item-title
                        class="text-h6 pb-5"
                        :style="{color: parameters.primaryColor}"
                    >Atendimento</v-list-item-title>
                    <v-list dense disabled class="transparent justify-center d-flex">
                        <AppFooterContactList/>
                    </v-list>
                </v-col>
                <v-col cols="12" class="py-12">
                    <ContactForm />
                </v-col>
                <v-col cols="12">
                    <ContactLocation />
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const AppFooterContactList = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterContactList.vue"
    );
const ContactForm = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/contact/ContactForm.vue"
    );
const ContactLocation = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/contact/ContactLocation.vue"
    );

export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        logoContact(){
            return `../../../../img/${process.env.VUE_APP_ENV_NAME}/logoContact.jpg`
        }
    },
    components:{
        AppFooterContactList,
        ContactForm,
        ContactLocation,
    },
    methods: {
        openSocialMedia(link) {
            window.open(link);
        },
        openWhatsApp(){
            window.open(`https://wa.me/${this.parameters.store.b2bwhats}?text=Ol%C3%A1%21%21+Gostaria+de+tirar+umas+d%C3%BAvidas...`)
        }
    },
};
</script>

<style>
</style>